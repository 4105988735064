body {
    margin: 0;
    padding: 0;
    width: 100%;
}

.table-area {
    overflow: scroll;
    width: 100%;
    height: 100vh;
}