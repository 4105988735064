.batch-edit {
    &-old-value {
        padding: 2px 3px;
        display: inline-block;
        background-color: #99000090;
        color: white;
    }

    &-new-value {
        padding: 2px 3px;
        display: inline-block;
        background-color: #00990090;
    }
}