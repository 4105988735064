.link {
  &-none {
    text-decoration: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-block {
  cursor: not-allowed;
}

.scrollable {
  overflow-y: auto !important;
  max-height: 800px;
}

.modal-dialog {
  &.modal-xl {
    width: 95% !important;
    min-width: 95% !important;

    .modal-content {
      margin: auto;
    }
  }
}

.alert-float {
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 9999;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.uppercase {
  text-transform: uppercase;
}

.modal {
  z-index: 999999999;

  &-backdrop {
    z-index: 99999999;
  }
}

.form-control {
  &.inline {
    display: inline-block;
    width: auto;
  }
}

.rule-keyword {
  background-color: white;
  padding: 3px 5px;
  border: solid 1px #ccc;
  display: inline-block;
  margin: 7px;
  font-weight: bold;
}

.lowercase {
  text-transform: lowercase !important;
}

form {
  &.inline {
    display: inline !important;
    width: auto;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.btn {
  &.inline {
    display: inline !important;
    vertical-align: auto !important;
    width: auto;
    padding: 0 !important;
    margin: 0 !important;
    text-decoration: none;
  }
}

*,
*:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none;
}

option:disabled {
  color: #d1d1d1;
}

.opacity-50 {
  opacity: 0.5;
}
